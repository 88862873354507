<template>
  <div>
    <!-- 底部 -->
    <div class="footer-box">
      <div class="site-footer clearfix" style="margin-left: -80px">
        <img
          src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/footer-1023-wglogo.png"
          alt=""
          style="height: 117px; margin-top: 25px; margin-right: 40px"
        />
        <div class="w container">
          <!-- 友情链接 -->
          <div class="footer-links">
            <dl class="col-links">
              <dt class="col-title" @click="goToWebsite('/product/wzmap')">
                产品中心
              </dt>
              <dd><a href="/product/wzmap">线上3D地图</a></dd>
              <dd><a href="/product/wzvr">微观全景</a></dd>
              <dd><a href="/product/wzcrm">微观房管家</a></dd>
              <dd><a href="/product/wzapplet">微甄房小程序</a></dd>
            </dl>
            <dl class="col-links">
              <dt class="col-title" @click="goToWebsite('/store')">门店服务</dt>
              <dd><a href="/store">门店服务</a></dd>
            </dl>
            <dl class="col-links">
              <dt class="col-title" @click="goToWebsite('/news')">新闻资讯</dt>
              <dd><a href="/news">新闻资讯</a></dd>
            </dl>
            <dl class="col-links">
              <dt
                class="col-title"
                @click="goToWebsite('https://vr.hfwgsj.com/')"
              >
                友情链接
              </dt>
              <dd>
                <a href="https://vr.hfwgsj.com/" target="_blank">微观全景</a>
              </dd>
              <dd>
                <a href="https://map.hfwgsj.com/" target="_blank">线上3D地图</a>
              </dd>
            </dl>
            <dl class="col-links">
              <dt class="col-title" @click="goToWebsite('/about/event')">
                关于我们
              </dt>
              <dd><a href="/about/event">大事记</a></dd>
              <dd><a href="/about/partner">合作伙伴</a></dd>
              <dd><a href="/about/contact">联系我们</a></dd>
            </dl>
            <span
              style="
                background-color: #333333;
                height: 156px;
                width: 1px;
                margin-right: 50px;
                margin-top: 10px;
              "
            ></span>
            <dl class="col-links">
              <dt style="margin-bottom: 20px">
                联系我们
                <span
                  style="
                    color: #16bbadff;
                    font-weight: 400;
                    font-family: Microsoft YaHei;
                    font-size: 24px;
                    text-align: center;
                  "
                  >400-029-9007</span
                >
              </dt>
              <dd
                class="contact-item"
                style="
                  font-family: Microsoft YaHei;
                  font-weight: 300;
                  font-size: 14px;
                  color: #646464;
                "
              >
                <img
                  src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/footer-ewm.png"
                  alt=""
                  style="width: 100px"
                />
                <span>
                  <dd>扫一扫添加微信</dd>
                  <dd>与您直接沟通</dd></span
                >
              </dd>
              <dd class="contact-item" style="margin-top: 20px">
                <img
                  src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/%E5%9C%A8%E7%BA%BF%E5%92%A8%E8%AF%A2%402x.png"
                  alt=""
                  style="width: 120px; height: 39px"
                  class="jumping-icon"
                />
              </dd>
              <dd class="contact-item"></dd>
            </dl>
            <dl class="col-links" style="margin-left: -80px">
              <dt
                style="
                  font-family: Microsoft YaHei;
                  font-weight: 300;
                  font-size: 14px;
                  color: #646464;
                  width: 300px;
                  margin-bottom: 20px;
                "
              >
                地址：安徽省合肥市瑶海区万国大厦
              </dt>
              <dd
                class="contact-item"
                style="
                  font-family: Microsoft YaHei;
                  font-weight: 300;
                  font-size: 14px;
                  color: #646464;
                "
              >
                <div>
                  <img
                    src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/wxgzh.png"
                    alt=""
                    style="height: 100px"
                  />
                  <span
                    style="
                      position: absolute;
                      margin-left: 10px;
                      margin-top: 25px;
                    "
                  >
                    <dd>扫一扫</dd>
                    <dd>关注微信账号</dd></span
                  >
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        background-color: #222222ff;
        color: #646464ff;
        font-size: 12px;
        line-height: 28px;
        display: flex;
        justify-content: center;
      "
      class="text-centerto"
    >
      <div>
        <div style="width: 100%; height: 1px; background-color: #333333"></div>
        <p
          class="text-center"
          style="
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: bold;
          "
        >
          关于微观世界
        </p>
        <p>
          微观世界是一家专门从事房产行业服务的高科技互联网公司，迄今已经成立12年了。12年来，公司与贝壳、链家、房天下等众多房产公司展开深度合作，服务大小门店超过3万家，收益经纪人超30万。现在微观世界顺应时势发展，推出房产直播新工具--微观《线上
        </p>
        <p>
          3D地图》。地图拉远看城市脉络、商圈板块，推进看城市项目，再推进看项目3D效果，还可以查看楼盘笔记、实景VR、日照模拟、详细的户型资料，雷达动态展示周边1.5公里的详细配套。最关键的是这些统统不需要自己动手，账号开通即可使用。随着短视频直播
        </p>
        <p>
          的崛起，让流量来到了直播互动的时代。《微甄房》小程序的上线，为众多房产主播提供了引流、获客、成交的一站式服务，助力房产经纪人占领新风口，抓住时代新红利！
        </p>
        <div
          class="img-bg"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 140px;
            margin-bottom: 30px;
            margin-top: 30px;
          "
        >
          <div style="display: flex; align-items: center; text-align: center">
            <img
              src="../assets/home/电脑-数据控制台@2x.png"
              alt=""
              style="width: 22px; height: 18px; margin-right: 10px"
            />
            <span style="margin-left: 10px">海量数据</span>
          </div>
          <div
            style="
              height: 33px;
              background: #292929;
              border-radius: 6px;
              border: 1px solid;
            "
          ></div>
          <div style="display: flex; align-items: center; text-align: center">
            <img
              src="../assets/home/客户@2x.png"
              alt=""
              style="width: 21px; height: 22px; margin-right: 18px"
            />
            <span>精准获客</span>
          </div>
          <div
            style="
              height: 33px;
              background: #292929;
              border-radius: 6px;
              border: 1px solid;
            "
          ></div>
          <div style="display: flex; align-items: center; text-align: center">
            <img
              src="../assets/home/高效@2x.png"
              alt=""
              style="width: 22px; height: 22px; margin-right: 18px"
            />
            <span>高效便捷</span>
          </div>
          <div
            style="
              height: 33px;
              background: #292929;
              border-radius: 6px;
              border: 1px solid;
            "
          ></div>
          <div style="display: flex; align-items: center; text-align: center">
            <img
              src="../assets/home/安全 (1)@2x.png"
              alt=""
              style="width: 21px; height: 23px; margin-right: 18px"
            />
            <span>安全可靠</span>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 1px;
            background-color: #333333;
            margin-top: 40px;
          "
        ></div>
      </div>
    </div>

    <!-- 备案信息 -->
    <div class="footer">
      <div class="footer-content">
        <span>安徽微观信息科技有限公司</span>
        <img
          src="../assets/home/ba_logo01.png"
          alt=""
          style="height: 25px; cursor: pointer"
          @click="goToWebsite()"
        />
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="font-weight: 400; font-size: 12px; color: #646464"
          target="_blank"
          >皖ICP备2021017992号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goToWebsite(query) {
      window.open(query);
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
    /* 上下幅度 */
  }
}

.jumping-icon {
  position: relative;
  animation: jump 3s infinite ease-in-out;
  /* 动画名称、持续时间、无限循环、缓动效果 */
  display: block;
  width: auto;
  height: 38px;
}

.footer-box {
  width: 100%;
  text-align: center;
  line-height: 40px;
  background-color: #222222ff;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1500px) {
    width: 100%;
  }
}

.site-footer {
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.text-centerto {
  .text-center {
    font-family: Microsoft YaHei;
  }

  .img-bg {
    width: 1438px;
    height: 63px;
    background-color: #292929ff;

    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #939393;
    }
  }
}

.site-footer .list-service li {
  float: left;
  width: 19.8%;
  height: 25px;
  border-left: 1px solid #e0e0e0;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}

.site-footer .list-service li:first-child {
  border-left: 0;
}

.site-footer .list-service a {
  color: #616161;
  transition: color 0.2s;
}

.site-footer .list-service em {
  margin-right: 6px;
  font-size: 24px;
  line-height: 24px;
  vertical-align: -4px;
}

.site-footer .list-service em.iconfont-circle-15 {
  vertical-align: -1px;
  font-size: 26px;
}

.site-footer .list-service a:hover {
  color: #ff6700;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.site-footer .footer-links .col-links {
  width: 120px;
  flex-wrap: nowrap;
  /* 禁止换行 */
  text-align: left;
  margin-bottom: 20px;
  margin-right: 20px;
  /* 添加右侧间距 */
}

.site-footer .footer-links .col-links .col-title:hover {
  color: #16bbadff;
  cursor: pointer;
}

.site-footer .footer-links .col-links:nth-last-child(2) {
  width: 330px;
  flex-wrap: nowrap;
  /* 禁止换行 */
  text-align: left;
  margin-bottom: 20px;
  margin-right: 20px;
  /* 添加右侧间距 */
  display: flex;
  flex-direction: column;

  .contact-item {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    gap: 8px;
    /* 图标和文字之间的间距 */
  }

  img {
    width: 20px;
    vertical-align: middle;
    /* 图标基线对齐 */
  }
}

.site-footer .footer-links .col-links dt {
  font-family: Microsoft YaHei;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
}

.site-footer .footer-links .col-links dd {
  font-family: Microsoft YaHei;
  font-size: 12px;
  margin-top: -12px;
  color: #727374;
}

.site-footer .footer-links .col-links a {
  font-size: 12px;
  color: #757575;
}

.site-footer .footer-links .col-links a:hover {
  color: #616161;
}

.site-footer .footer-links .col-contact {
  float: right;
  width: 251px;
  border-left: 1px solid #e0e0e0;
  text-align: center;
  color: #616161;
}

.footer {
  width: 100%;

  text-align: center;
  background-color: #222222ff;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */

  .footer-links a {
    margin: 0 10px;
  }

  .footer-content {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    gap: 10px;
    /* 元素间距 */
  }

  span {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #646464;
    line-height: 70px;
  }

  @media screen and (max-width: 1500px) {
    width: 100%;
  }
}
</style>
