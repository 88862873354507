var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"list"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/home/wgsj_login.png"),"alt":"微观世界线上地图"},on:{"click":function($event){return _vm.goPage(0)}}}),_c('div',{staticClass:"nav-box"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:['list-item', { active: index === _vm.active }, 'list-item-box'],on:{"click":function($event){$event.stopPropagation();return _vm.goPage(index)},"mouseenter":function($event){return _vm.handleMouseEnter(index)},"mouseleave":function($event){return _vm.handleMouseLeave(index)}}},[[(index === 7)?_c('div',{staticStyle:{"background":"#e7f9f8","border-radius":"6px","width":"172px","height":"38px","display":"flex","align-items":"center","justify-content":"center","font-family":"Microsoft YaHei","font-weight":"400","font-size":"16px"}},[_c('img',{staticStyle:{"width":"20px","height":"20px","margin-right":"4px"},attrs:{"src":require("../assets/home/下载@2x.png"),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_vm._v(_vm._s(item.name))])],_c('transition',{attrs:{"name":"fade"}},[(_vm.showDropdown && index === 1)?_c('div',{staticClass:"dropdown"},[_c('ul',_vm._l((_vm.dropdownOptions),function(option,idx){return _c('li',{key:idx,on:{"click":function($event){$event.stopPropagation();return _vm.handleOptionClick(option, idx)},"mouseover":function($event){return _vm.handleMouseOver(idx)},"mouseout":function($event){return _vm.handleMouseOut(idx)}}},[(item.label != '微观全景')?_c('img',{attrs:{"src":option.isHovered || option.isAutoSelect
                      ? option.url1
                      : option.url2,"alt":""}}):_c('img',{staticStyle:{"width":"26.64px","height":"20.06px"},attrs:{"src":option.isHovered || option.isAutoSelect
                      ? option.url1
                      : option.url2,"alt":""}}),_c('br'),_c('a',{staticStyle:{"font-family":"Microsoft YaHei, Microsoft YaHei","font-weight":"400","font-size":"16px","line-height":"0px","text-align":"left","font-style":"normal","text-transform":"none","opacity":"0.8"},style:({
                    color:
                      option.isHovered || option.isAutoSelect
                        ? '#262626FF'
                        : '#9DA3B4FF',
                  })},[_vm._v(_vm._s(option.label))])])}),0)]):_vm._e()])],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }