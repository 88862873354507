<template>
  <div class="container">
    <div class="list">
      <img
        src="../assets/home/wgsj_login.png"
        alt="微观世界线上地图"
        class="logo"
        @click="goPage(0)"
      />
      <div class="nav-box">
        <div
          v-for="(item, index) in navList"
          :key="index"
          @click.stop="goPage(index)"
          :class="['list-item', { active: index === active }, 'list-item-box']"
          @mouseenter="handleMouseEnter(index)"
          @mouseleave="handleMouseLeave(index)"
        >
          <template>
            <div
              v-if="index === 7"
              style="
                background: #e7f9f8;
                border-radius: 6px;
                width: 172px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
              "
            >
              <img
                src="../assets/home/下载@2x.png"
                alt=""
                style="width: 20px; height: 20px; margin-right: 4px"
              />
              {{ item.name }}
            </div>
            <div v-else>{{ item.name }}</div>
          </template>

          <transition name="fade">
            <div v-if="showDropdown && index === 1" class="dropdown">
              <ul>
                <li
                  v-for="(option, idx) in dropdownOptions"
                  :key="idx"
                  @click.stop="handleOptionClick(option, idx)"
                  @mouseover="handleMouseOver(idx)"
                  @mouseout="handleMouseOut(idx)"
                >
                  <img
                    v-if="item.label != '微观全景'"
                    :src="
                      option.isHovered || option.isAutoSelect
                        ? option.url1
                        : option.url2
                    "
                    alt=""
                  />
                  <img
                    v-else
                    :src="
                      option.isHovered || option.isAutoSelect
                        ? option.url1
                        : option.url2
                    "
                    alt=""
                    style="width: 26.64px; height: 20.06px"
                  />
                  <br />
                  <a
                    style="
                      font-family: Microsoft YaHei, Microsoft YaHei;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 0px;
                      text-align: left;
                      font-style: normal;
                      text-transform: none;
                      opacity: 0.8;
                    "
                    :style="{
                      color:
                        option.isHovered || option.isAutoSelect
                          ? '#262626FF'
                          : '#9DA3B4FF',
                    }"
                    >{{ option.label }}</a
                  >
                </li>
              </ul>
            </div>
          </transition>

          <!--          <transition name="fade">
            <div v-if="aboutShowDropdown && index === 4" class="dropdown">
              <ul>
                <li
                  v-for="(option, idx) in aboutDropdownOptions"
                  :key="idx"
                  @click.stop="handleOptionClickAbout(option, idx)"
                  @mouseover="handleMouseOverAbout(idx)"
                  @mouseout="handleMouseOutAbout(idx)"
                >
                  <img
                    :src="option.isHovered || option.isAutoSelect ? option.url1 : option.url2"
                    alt=""
                  />
                  <br />
                  <a
                    style="
                      font-family: Microsoft YaHei, Microsoft YaHei;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 0px;
                      text-align: left;
                      font-style: normal;
                      text-transform: none;
                    "
                    :style="{
                      color:
                        option.isHovered || option.isAutoSelect ? '#262626FF' : '#9DA3B4FF',
                    }"
                    >{{ option.label }}</a
                  >
                </li>
              </ul>
            </div>
          </transition> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "首页",
          href: "",
        },
        {
          name: "产品介绍",
          href: "product",
        },
        {
          name: "房产地图",
          href: "https://map.hfwgsj.com/",
        },
        {
          name: "微观全景",
          href: "https://vr.hfwgsj.com/",
        },
        {
          name: "微观房管家",
          href: "https://manage.hfwgsj.com/index",
        },
        {
          name: "门店服务",
          href: "store",
        },
        {
          name: "微观资讯",
          href: "news",
        },
      ],
      active: 0,
      showDropdown: false,
      aboutShowDropdown: false,
      dropdownOptions: [
        {
          label: "房产地图",
          href: "/wzmap",
          url1: require("../assets/nav/s-m.png"),
          url2: require("../assets/nav/w-m.png"),
          isHovered: false,
          isAutoSelect: false,
        },
        {
          label: "微观全景",
          href: "/wzvr",
          url1: require("../assets/nav/s-vr.png"),
          url2: require("../assets/nav/w-vr.png"),
          isHovered: false,
          isAutoSelect: false,
        },
        {
          label: "微观房管家",
          href: "/wzcrm",
          url1: require("../assets/nav/s-f.png"),
          url2: require("../assets/nav/w-f.png"),
          isHovered: false,
          isAutoSelect: false,
        },
        {
          label: "微甄房小程序",
          href: "/wzapplet",
          url1: require("../assets/nav/s-x.png"),
          url2: require("../assets/nav/w-x.png"),
          isHovered: false,
          isAutoSelect: false,
        },
      ],
      aboutDropdownOptions: [
        {
          label: "大事记",
          href: "/event",
          url1: require("../assets/nav/s-dsj.png"),
          url2: require("../assets/nav/w-dsj.png"),
          isHovered: false,
          isAutoSelect: false,
        },
        {
          label: "合作伙伴",
          href: "/partner",
          url1: require("../assets/nav/s-h.png"),
          url2: require("../assets/nav/w-h.png"),
          isHovered: false,
          isAutoSelect: false,
        },
        {
          label: "联系我们",
          href: "/contact",
          url1: require("../assets/nav/s-l.png"),
          url2: require("../assets/nav/w-l.png"),
          isHovered: false,
          isAutoSelect: false,
        },
      ],
    };
  },
  mounted() {
    this.updateActiveRoute();
    this.$router.afterEach(this.updateActiveRoute);
  },
  beforeDestroy() {
    this.$router.afterEach(() => {});
  },
  methods: {
    handleMouseOver(idx) {
      this.dropdownOptions[idx].isHovered = true;
    },
    handleMouseOut(idx) {
      this.dropdownOptions[idx].isHovered = false;
    },
    handleMouseOverAbout(idx) {
      this.aboutDropdownOptions[idx].isHovered = true;
    },
    handleMouseOutAbout(idx) {
      this.aboutDropdownOptions[idx].isHovered = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    goPage(val) {
      console.log(val);
      if (val === 1) {
        return;
      }
      if (val === 2 || val === 3 || val === 4) {
        window.open(this.navList[val].href);
        return;
      } else {
        this.active = val;
        const href = this.navList[val].href;
        this.$router.push("/" + href);

        // 重置所有子选项的选中状态
        this.resetDropdownOptions();

        // 滚动到页面顶部
        this.scrollToTop();
      }
    },
    handleOptionClick(option, idx) {
      const href = option.href;
      this.active = 1; // 选中“产品中心”
      this.$router.push("/product" + href);

      // 重置所有子选项的选中状态
      this.resetDropdownOptions();

      // 设置当前选中的子选项为选中状态
      this.dropdownOptions[idx].isAutoSelect = true;

      // 滚动到页面顶部
      this.scrollToTop();
    },
    handleOptionClickAbout(option, idx) {
      const href = option.href;
      this.active = 4; // 选中“关于我们”
      this.$router.push("/about" + href);

      // 重置所有子选项的选中状态
      this.resetDropdownOptions();

      // 设置当前选中的子选项为选中状态
      this.aboutDropdownOptions[idx].isAutoSelect = true;

      // 滚动到页面顶部
      this.scrollToTop();
    },
    handleMouseEnter(index) {
      if (index === 1) {
        this.showDropdown = true;
        this.aboutShowDropdown = false;
      }
      if (index === 4) {
        this.showDropdown = false;
        this.aboutShowDropdown = true;
      }
    },
    handleMouseLeave() {
      this.showDropdown = false;
      this.aboutShowDropdown = false;
    },
    updateActiveRoute() {
      const currentPath = this.$route.path;

      const routes = {
        "/product": 1,
        "/news": 6,
        /* "/about": 4, */
        "/store": 5,
      };

      // 默认值
      this.active = 0;

      for (const route in routes) {
        if (currentPath.indexOf(route) !== -1) {
          this.active = routes[route];
          break; // 一旦匹配成功，跳出循环
        }
      }

      // 检查子菜单
      this.dropdownOptions.forEach((option, idx) => {
        if (currentPath.indexOf(option.href) !== -1) {
          this.active = 1; // 选中“产品中心”
          this.dropdownOptions[idx].isAutoSelect = true;
        }
      });

      this.aboutDropdownOptions.forEach((option, idx) => {
        if (currentPath.indexOf(option.href) !== -1) {
          this.active = 4; // 选中“关于我们”
          this.aboutDropdownOptions[idx].isAutoSelect = true;
        }
      });
    },
    resetDropdownOptions() {
      this.dropdownOptions.forEach((option) => {
        option.isHovered = false;
        option.isAutoSelect = false;
      });
      this.aboutDropdownOptions.forEach((option) => {
        option.isHovered = false;
        option.isAutoSelect = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list-item-box {
  &:hover {
    color: rgb(13, 216, 189);
  }
}
a {
  text-decoration: none;
  /* 去除下划线 */
  color: inherit;
  /* 继承父元素的文字颜色 */
  cursor: pointer;
  /* 设置光标样式为指针 */
}

.container {
  width: 100vw;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 999999;
  justify-content: center;
  background-color: #ffffffff;

  @media screen and (max-width: 1200px) {
    width: 1200px;
  }

  .list {
    width: 1300px;
    height: 60px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: center;

    .logo {
      height: 40px;
      cursor: pointer;
    }

    .nav-box {
      width: 750px;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 100px;
      cursor: pointer;

      .list-item {
        font-size: 16px;
        color: #000000;
        &:hover {
          color: rgb(13, 216, 189);
        }
      }

      .active {
        color: rgb(13, 216, 189);
      }

      .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100px;
        background: #ffffffff;
        border-radius: 0px 0px 0px 0px;
        border-top: 1px solid #dbdbdb;
        z-index: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-around;

          img {
            margin-bottom: 8px;
          }

          li {
            padding: 50px;
            cursor: pointer;

            a {
              color: #262626ff;
              text-decoration: none;
              text-align: center;
            }
          }
        }
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2;
      }

      .fade-enter-from,
      .fade-leave-to {
        opacity: 0;
      }
    }

    .login {
      background-color: rgb(214, 229, 226);
      color: rgb(13, 216, 189);
      border-radius: 5px;
      padding: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;

      a {
        color: rgb(13, 216, 189);
      }

      &:hover {
        cursor: pointer;
        background-color: rgb(13, 216, 189);
        animation: fade 1s;

        a {
          color: #fff;
        }
      }

      @keyframes fade {
        from {
          opacity: 0.5;
        }

        to {
          opacity: 1;
        }
      }

      .user {
        width: 22px;
        height: 22px;
        margin-left: 5px;
      }
    }
  }
}
</style>
